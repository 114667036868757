<template>
  <div class="content-wrapper">
    <section class="content">
      <section style="margin-bottom: 1%">
        <a-button type="primary" @click="createPaymentNote">
          <i class="icon iconfont icon-new" style="margin-right: 5px"></i>
          {{ $t('新增付款单') }}
        </a-button>
      </section>
      <a-row :gutter="16">
        <a-col :span="6">
          <a-input allow-clear v-model.trim="formData.noteSn" :placeholder="$t('请输入付款单号')" />
        </a-col>
        <a-col :span="6">
          <DateRangePicker
            id="requestTime"
            :needhms="false"
            @get_date="chooseRequestTime"
            :placeholder="$t(`请款时间`)"
            :start-time="formData.applyTimeStart"
            :end-time="formData.applyTimeEnd"
          />
        </a-col>
        <a-col :span="6">
          <CommonQuerySelect
            api="queryPurchaseSupplierList"
            :params="{
              status: 1,
            }"
            :placeholder="$t(`收款组织`)"
            :code.sync="formData.supplierCode"
            :name.sync="formData.supplierName"
            valueName="supplier_code"
            labelName="supplier_name"
          />
        </a-col>
        <a-col :span="6">
          <CommonSelect
            :placeholder="$t('单据类型')"
            :list="payment_note_type"
            :code.sync="formData.orderType"
          />
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mt10">
        <a-col :span="6">
          <a-button @click="searchHandle(1)" class="btnBgc">
            <i class="iconfont" style="margin-right: 5px">&#xe647;</i>
            {{ $t(`查询`) }}
          </a-button>
          <a-button @click="reset">
            <a-icon type="redo" />
            {{ $t(`重置`) }}
          </a-button>
        </a-col>
      </a-row>

      <CommonTable
        ref="commonTable"
        class="mt10"
        :showSelect="true"
        :selectedRowKeys.sync="selectedRowKeys"
        :columns.sync="paymentNoteColumns"
        :data-source="paymentNoteData"
        :statusList="payment_note_status"
        rowKey="id"
        :scroll="{ x: 1500, y: scrollHeight }"
        :loading="loading"
        :total="total"
        @select="onSelectChange"
        @search="queryPaymentNoteList"
      >
        <template v-slot:header>
          <div class="flex-ct">
            <OperateBtn
              api="checkOrder"
              :text="$t('审核')"
              :params="{
                docType: docType,
                idList: selectedRowKeys,
              }"
              :disabled="selectedRows.length === 0"
              @success="searchHandle"
            />
            <OperateBtn
              class="ml10"
              :disabled="!selectedRowKeys.length"
              api="payPaymentNote"
              :text="$t('付款')"
              :params="{
                idList: selectedRowKeys,
              }"
              @success="searchHandle"
            />
            <VoucherBtn
              class="ml10"
              :docType="docType"
              :selectedRows="selectedRows"
              :disabled="selectedRows.length === 0"
              @close="searchHandle"
            />
          </div>
        </template>
        <template v-slot:paymentNoteSn="{ text, record }">
          <span class="text-link" @click="viewDetail(record)">{{ text }}</span>
        </template>
        <template v-slot:paymentAmount="{ text, record }">
          <span v-if="text">{{ text | currencySymbol(record.paymentCurrency) }}</span>
        </template>
      </CommonTable>
    </section>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import http from '@/service/axios'
import api from '@/service/api'
import CommonSelect from '@component/commonSelect'
import CommonTable from '@component/commonTable'
import DateRangePicker from '@component/dateRangePicker'
import VoucherBtn from '@component/voucherBtn'
import { spaceToComma, convertKeysToCamelCase } from '@/common'

export default {
  name: 'paymentNoteList',
  components: { CommonSelect, CommonTable, DateRangePicker, VoucherBtn },
  data() {
    return {
      docType: 'PaymentNote',
      formData: {
        noteSn: '',
        applyTimeStart: undefined,
        applyTimeEnd: undefined,
        orderType: undefined,
      },
      total: 0,
      loading: false,
      paymentNoteData: [],
      selectedRowKeys: [],
      selectedRows: [],

      paymentNoteColumns: [
        {
          title: this.$t('付款组织'),
          dataIndex: 'businessUnitName',
          width: 200,
        },
        {
          title: this.$t('付款单号'),
          dataIndex: 'paymentNoteSn',
          width: 200,
          scopedSlots: { customRender: 'paymentNoteSn' },
        },
        {
          title: this.$t('单据类型'),
          dataIndex: 'orderType',
          width: 150,
          customRender: (text) => this.paymentNoteTypeMapping[text],
        },
        {
          title: this.$t('单据状态'),
          dataIndex: 'status',
          customRender: (text) => this.paymentNoteStatusMapping[text],
          width: 150,
        },
        {
          title: this.$t('业务日期'),
          dataIndex: 'accountingDate',
          width: 150,
          customRender: (text) => text?.split(' ')[0],
        },
        {
          title: this.$t('付款货币'),
          dataIndex: 'paymentCurrency',
          width: 150,
          customRender: (text) => this.currencyListMapping[text],
        },
        {
          title: this.$t('付款金额'),
          dataIndex: 'paymentAmount',
          scopedSlots: { customRender: 'paymentAmount' },
          width: 150,
        },
        {
          title: this.$t('收款组织'),
          dataIndex: 'supplierName',
          width: 200,
        },
        {
          title: this.$t('结算方式'),
          dataIndex: 'settlementMethodName',
          width: 150,
        },
        {
          title: this.$t('已生成凭证'),
          dataIndex: 'voucherGenerated',
          width: 150,
          customRender: (text) => (+text ? this.$t('是') : this.$t('否')),
        },
        {
          title: this.$t('凭证号'),
          dataIndex: 'voucherNo',
          width: 200,
        },
        {
          title: this.$t('备注'),
          dataIndex: 'remark',
          width: 150,
        },
        {
          title: this.$t('创建人'),
          dataIndex: 'createdUser',
          width: 150,
        },
      ],
    }
  },
  activated() {
    this.searchHandle()
  },
  computed: {
    ...mapState(['payment_note_status', 'payment_note_type']),
    ...mapGetters(['paymentNoteStatusMapping', 'paymentNoteTypeMapping', 'currencyListMapping']),
    scrollHeight() {
      return window.innerHeight - 470
    },
  },
  methods: {
    ...mapMutations(['addPane']),
    reset() {
      Object.assign(this.$data, this.$options.data())
      this.searchHandle(1)
    },
    searchHandle(pageNo) {
      this.selectedRows = []
      this.selectedRowKeys = []
      this.$refs.commonTable.queryList(pageNo)
    },
    createPaymentNote() {
      this.$router.push({ name: 'paymentNoteDetail' })
    },
    chooseRequestTime(date) {
      this.formData.applyTimeStart = date.start_time
      this.formData.applyTimeEnd = date.end_time
    },
    async queryPaymentNoteList(params) {
      const data = {
        ...this.formData,
        ...params,
      }
      let noteSn = spaceToComma(this.formData.noteSn)
      if (noteSn.includes(',')) {
        data.noteSnList = noteSn.split(',')
      } else if (noteSn) {
        data.noteSn = noteSn
      }
      this.loading = true
      const res = await http({
        type: 'post',
        url: api.getPaymentNoteList,
        data,
      })
      this.paymentNoteData = convertKeysToCamelCase(res.result.list) || []
      this.total = res.result.total
      this.loading = false
    },
    viewDetail(record) {
      this.addPane({
        view: 'paymentNoteDetail/' + record.paymentNoteSn,
        name: 'detailSn',
        query: {
          id: record.id,
          sn: record.paymentNoteSn,
        },
        route: `/payableManage/paymentNoteDetail?id=${record.id}&sn=${record.paymentNoteSn}`,
      })
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
  },
}
</script>

<style lang="scss" scoped>
.btnBgc {
  background-color: #0088ff;
  color: #ffffff;
}
</style>
